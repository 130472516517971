import React, {useCallback, useEffect, useRef, useState} from 'react';
import useInfiniteScroll from '../helpers/useInfiniteScroll';
import Link from "../../../models/Link";
import {useApiService} from "../../../context/ApiServiceContext";
import {Form} from 'react-bootstrap';
import {debounce, trimToHostname} from "../../../utils/utils";
import {makeStyles} from "@material-ui/core";
import {Divider, Typography} from "@mui/material";
import Category from "../../../models/Category";

const useStyles = makeStyles({
    cell: {
        cursor: "pointer",
        padding: "5px 20px",
        "&:hover": {
            background: "#f7f7f7"
        }
    }
})

interface CustomSelectProps {
    value: string;
    onChange: (link: Link) => void;
    disabled: boolean;
    isInvalid: boolean;
    required: boolean;
    siteType?: string;
    anchor?: string;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
                                                       value, onChange,
                                                       disabled,
                                                       isInvalid,
                                                       required,
                                                       siteType,
                                                       anchor
                                                   }) => {
    const classes = useStyles()
    const [links, setLinks] = useState<Link[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [query, setQuery] = useState(trimToHostname(value, ''));
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(query);
    const [debouncedAnchor, setDebouncedAnchor] = useState(anchor);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const loader = useRef(null);
    const me = useRef<HTMLInputElement>(null);
    const closeDropdown = useRef(true);
    const apiService = useApiService();
    const [showDropdown, setShowDropdown] = useState(false);
    const invokeSearch = useRef(true);
    const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);
    const [showNotUsedForAnchor, setShowNotUsedForAnchor] = useState(true);
    const dropdownRef = useRef<HTMLDivElement>(null); // Ref for dropdown container

    useInfiniteScroll(loader, () => {
        if (hasMore && !loading) {
            setPage(page + 1);
        }
    }, [hasMore, loading]);


    const fetchMore = useCallback(async () => {
        if (!invokeSearch.current) {
            invokeSearch.current = true
            return
        }

        // Fetch more links
        const perPage = 20;
        setLoading(true);
        const categoriesToLoad = selectedCategoryId || Array.from(new Set(categories.map(x => x.id))).filter(x => x).join(',')
        // load all categories for now
        // prev implementation: &categoryIds=${categoriesToLoad}
        if (categories.length == 0) {
            const catRes = await apiService.api.get(`/category/list?per_page=100`)
            setCategories(catRes.data)
        }

        let path = `/link/list?per_page=${perPage}&page=${page}`
            + (siteType ? `&siteType=${siteType}` : '')
            + (selectedCategoryId ? `&categoryIds=${categoriesToLoad}` : '')
            + `${debouncedSearchTerm ? `&website=${debouncedSearchTerm}` : ''}`

        if (showNotUsedForAnchor && debouncedAnchor && debouncedAnchor != '') {
            path += `&notUsedForAnchor=${debouncedAnchor}`
        }

        apiService.api.get(path)
            .then(response => response.data)
            .then(data => {
                if (page == 1) {
                    setLinks(data)
                } else {
                    setLinks(prev => [...prev, ...data])
                }

                setHasMore(data.length == perPage);
                setLoading(false);
            })
            .catch(error => console.error('Error fetching links:', error));

    }, [page, debouncedSearchTerm, showNotUsedForAnchor, selectedCategoryId, debouncedAnchor]);

    useEffect(() => {
        fetchMore();
    }, [fetchMore]);

    useEffect(() => {
        const {debouncedFunc, timerId} = debounce(() => {
            setPage(1);
            setDebouncedSearchTerm(query);
        }, 500);
        debouncedFunc();
        return () => {
            const timer = timerId();
            if (timer) clearTimeout(timer);
        };
    }, [query]);

    useEffect(() => {
        const {debouncedFunc, timerId} = debounce(() => {
            setPage(1);
            setDebouncedAnchor(anchor);
        }, 500);
        debouncedFunc();
        return () => {
            const timer = timerId();
            if (timer) clearTimeout(timer);
        };
    }, [anchor]);

    const handleFocus = () => {
        setShowDropdown(true);
    };

    // When an item is clicked or when the input loses focus, hide the dropdown
    const handleBlur = () => {
        // Timeout to allow for item click to register
        setTimeout(() => {
            if (closeDropdown.current) {
                setShowDropdown(false)
            }
            closeDropdown.current = true
        }, 150);
    };

    // Handle dropdown item click
    const handleSelect = (link: Link) => {
        invokeSearch.current = false
        setQuery(trimToHostname(link.website))
        onChange(link);
        setShowDropdown(false);
    };

    const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSelectedCategoryId(event.target.value == '' ? null : event.target.value);
        setPage(1);
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        closeDropdown.current = false
        setShowNotUsedForAnchor(event.target.checked);
        setPage(1); // Reset page to 1 when checkbox state changes
        me.current?.focus()
    };

    const handleDropdownMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        if (dropdownRef.current) {
            const rect = dropdownRef.current.getBoundingClientRect();
            // Calculate scrollbar width (if any)
            const scrollbarWidth = dropdownRef.current.offsetWidth - dropdownRef.current.clientWidth;
            // If there is a scrollbar and the click is within its area (on the right edge)
            if (scrollbarWidth > 0 && ((e.clientX > rect.left - scrollbarWidth) ||
                (e.clientX > rect.right - scrollbarWidth))) {
                closeDropdown.current = false;
            }
        }
    };

    const handleDropdownMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
        if (me.current) {
            me.current.focus()
        }
    };

    return (
        <>
            {!disabled && <div>
                <Form.Control as="select"
                              onChange={handleCategoryChange}
                              value={selectedCategoryId || ''}
                              disabled={disabled}
                              className="mb-2">
                    <option value=''>כל הקטגוריות</option>
                    {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                            {category.name}
                        </option>
                    ))}
                </Form.Control>
            </div>}
            <Form.Control
                ref={me}
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onFocus={handleFocus}
                onBlur={handleBlur}
                disabled={disabled}
                required={required}
                autoComplete="off"
                className={isInvalid ? 'is-invalid' : ''}
                placeholder={"אתר מקשר (האתר בו יופיע המאמר עם הקישור)"}
            />
            {showDropdown && (
                <div
                    ref={dropdownRef}
                    className="custom-dropdown"
                     style={{
                         position: 'absolute',
                         zIndex: 1050,
                         background: 'white',
                         padding: '10px 0',
                         border: '1px solid gainsboro',
                         borderRadius: '7px',
                         marginTop: '6px',
                         maxHeight: '260px',
                         overflowY: 'auto',
                         width: "290px"
                     }}
                     onMouseDown={handleDropdownMouseDown}
                     onMouseUp={handleDropdownMouseUp}>
                    <div className="options-container">
                        {debouncedAnchor &&
                            <>
                                <Form.Check
                                    type="checkbox"
                                    label="הצג אתרים שלא פרסמתי בהם בלבד"
                                    checked={showNotUsedForAnchor}
                                    onChange={handleCheckboxChange}
                                    style={{paddingRight: "40px"}}
                                    className="pt-1 pb-1"
                                />
                                <Divider/>
                            </>
                        }
                        {links.length > 0 ? links.map(link => (
                                <div key={link.id}>
                                    <div className={classes.cell} onClick={() => handleSelect(link)}>
                                        <Typography className="pe-none"
                                                    fontWeight={600}>{trimToHostname(link.website)}</Typography>
                                        <div className="pe-none">DR {link.metadata?.dr} |
                                            RD {link.metadata?.rd} {!selectedCategoryId && `| ${link.category.name}`}</div>
                                    </div>
                                    <Divider style={{margin: "0 10px"}}/>
                                </div>
                            )) :
                            <div className={"px-3 pt-3 pb-1"}>לא נמצאו אתרים העונים לחיפוש</div>
                        }

                        <div ref={loader}/>
                    </div>
                </div>
            )}
        </>
    );
};

export default CustomSelect;
