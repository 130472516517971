import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, InputGroup, Modal, Row, Table} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {useApiService} from "../../context/ApiServiceContext";
import User from "../../models/User";
import {useFormik} from 'formik';
import * as yup from 'yup';
import ConfirmationModal from "../../utils/ConfirmationModal";
import AttachPackageToUserModal from "./user/AttachPackageToUser";
import AttachLinkToUserModal from "./user/AttachLinkToUser";
import InfiniteAutocompleteSelect from "./shared/InfiniteAutocompleteSelect";
import {Box} from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SortIcon from '@mui/icons-material/Sort';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


const AdminUsers: React.FC = () => {
    const apiService = useApiService();
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [showUserDetailsModal, setShowUserDetailsModal] = useState(false);
    const [showPackagesModal, setShowPackagesModal] = useState(false);
    const [showLinksModal, setShowLinksModal] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);

    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    // Pagination and filter state
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const [currentFilter, setCurrentFilter] = useState<[string, 'email' | 'name'] | null>(null);
    const linksPerPage = 20;

    // sort states
    const [sortBy, setSortBy] = useState<'createdAt' | 'name'>('createdAt');
    const [sortDirection, setSortDirection] = useState<1 | -1>(-1);

    // fetch users
    useEffect(() => {
        const fetchUsers = async () => {
            // build API url with page, filter, sortBy, sortDirection
            let url = `/user/list?page=${currentPage}&perPage=${linksPerPage}&sortBy=${sortBy}&sortDirection=${sortDirection}`;
            if (currentFilter && currentFilter[0] !== '') {
                url += `&${currentFilter[1]}=${encodeURIComponent(currentFilter[0])}`;
            }
            try {
                const res = await apiService.api.get(url);
                setUsers(res.data);
                setHasMorePages(res.data.length === linksPerPage);
            } catch (error) {
                toast.error('Error fetching users');
                console.error(error);
            }
        };
        fetchUsers();
    }, [apiService, currentPage, currentFilter, sortBy, sortDirection]);

    // handle sorting
    const handleSort = (column: 'createdAt' | 'name') => {
        // if same column, toggle direction; else set new column, reset to asc
        if (sortBy === column) {
            setSortDirection(prevDirection => (prevDirection === 1 ? -1 : 1));
        } else {
            setSortBy(column);
            setSortDirection(1);
        }
        setCurrentPage(1);
    };

    // Handle email filter change
    const handleFilterChange = (type: 'email' | 'name', value?: string | undefined) => {
        setCurrentFilter([value ?? '', type]);
        setCurrentPage(1);
    };

    const handleDeleteConfirmation = useCallback(() => {
        if (selectedUser) {
            const userId = selectedUser.id;
            apiService.api
                .delete(`/user/${userId}`)
                .then(() => {
                    setUsers(users.filter((user) => user.id !== userId));
                    toast.success('המשתמש נמחק בהצלחה');
                })
                .catch(() => {
                    toast.error('תקלה בעת מחיקת משתמש');
                })
                .finally(() => {
                    setDeleteModalShow(false);
                    setSelectedUser(null);
                });
        }
    }, [selectedUser]);

    const handleUpdate = (values: any) => {
        if (selectedUser) {
            const updatedUser = {
                ...values,
            };

            delete updatedUser.email;
            updatedUser.websites = updatedUser.websites.filter((x: string) => x !== "");

            apiService.api
                .put(`/user/${selectedUser.id}`, updatedUser)
                .then((response) => {
                    setUsers((prevUsers) =>
                        prevUsers.map((user) =>
                            user.id === response.data.id ? response.data : user
                        )
                    );
                    toast.success('המשתמש עודכן בהצלחה');
                })
                .catch(() => {
                    toast.error('תקלה בעת עדכון משתמש');
                });

            setShowModal(false);
        }
    };

    // modals
    const openModal = (user: User | null) => {
        setSelectedUser(user);
        setShowModal(true);
    };

    const openDeleteModal = (user: User) => {
        setSelectedUser(user);
        setDeleteModalShow(true);
    };

    const openUserDetailsModal = (user: User) => {
        setSelectedUser(user);
        setShowUserDetailsModal(true);
    };

    // formik for user edit
    const userSchema = yup.object().shape({
        name: yup.string().required('שם משתמש נדרש'),
        phone: yup.string().nullable().matches(/^[0-9]+$/, 'מספר טלפון חייב להיות מספרי'),
        email: yup.string().required('דוא"ל נדרש').email('כתובת דוא"ל לא תקינה'),
        field: yup.string().nullable(),
        clientType: yup.string().nullable(),
        companySize: yup.number().nullable().min(0, 'גודל חברה לא יכול להיות שלילי'),
        websites: yup.array().of(yup.string().url('כתובת אתר לא חוקית')),
        admin: yup.boolean(),
    });

    const formik = useFormik({
        initialValues: {
            name: selectedUser?.name ?? '',
            phone: selectedUser?.phone ?? null,
            email: selectedUser?.email ?? '',
            field: selectedUser?.field ?? null,
            clientType: selectedUser?.clientType ?? null,
            companySize: selectedUser?.companySize ?? null,
            websites: selectedUser?.websites ?? [],
            admin: selectedUser?.admin ?? false,
        },
        validationSchema: userSchema,
        onSubmit: (values) => {
            handleUpdate(values);
        },
        validateOnBlur: true,
        validateOnMount: true,
        enableReinitialize: true,
    });

    // formik for reset password
    const resetPasswordFormik = useFormik({
        initialValues: {
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema: yup.object({
            newPassword: yup.string().required('סיסמה נדרשת')
                .min(6, 'הסיסמא צריכה להכיל לפחות 6 תווים')
                .matches(/[a-z]/, 'הסיסמא צריכה להכיל לפחות אות קטנה אחת')
                .matches(/[A-Z]/, 'הסיסמא צריכה להכיל לפחות אות גדולה אחת')
                .matches(/[0-9]/, 'הסיסמא צריכה להכיל לפחות מספר אחד')
                .matches(/[!@#$%^&*(),.?":{}|<>]/, 'הסיסמא צריכה להכיל לפחות תו מיוחד אחד'),
            confirmPassword: yup.string().oneOf([yup.ref('newPassword')], 'סיסמאות אינן תואמות').required('אימות סיסמה נדרש'),
        }),
        onSubmit: (values) => {
            if (selectedUser) {
                apiService.api
                    .post(`/admin/user/${selectedUser.id}/password/reset`, {newPassword: values.newPassword})
                    .then(() => {
                        toast.success('הסיסמה עודכנה בהצלחה');
                    })
                    .catch(() => {
                        toast.error('תקלה בעת עדכון סיסמה');
                    })
                    .finally(() => {
                        setShowResetPasswordModal(false);
                        setShowNewPassword(false);
                        setShowConfirmPassword(false);
                        resetPasswordFormik.resetForm()
                    });
            }
        },
    });

    const PaginationBar = () => {
        return (
            <div className="d-flex justify-content-center my-3">
                <Button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className="me-2"
                >
                    הקודם
                </Button>
                <Button
                    disabled={!hasMorePages}
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    הבא
                </Button>
            </div>
        );
    };

    const handleWebsitesChange = (websites: string) => {
        const websitesArray = websites.split(',').map(site => site.trim());
        formik.setFieldValue('websites', websitesArray);
    };

    return (
        <div className="row justify-content-center py-md-5">
            <Row className="justify-content-center py-md-5 col-md-10">
                <Col md={4}>
                    <h1 className="mb-2">משתמשים</h1>
                </Col>
                <Col md={4} className="d-flex justify-content-end align-items-center">
                    <Box className="me-1 w-100">
                        <InfiniteAutocompleteSelect
                            entityName="אימייל"
                            filterProp={'email'}
                            handleSelected={selectedValue => handleFilterChange('email', selectedValue)}
                            fetchEndpoint={'/user/suggestions'}
                            clearInput={currentFilter?.[1] === 'name'}
                        />
                    </Box>
                </Col>
                <Col md={4} className="d-flex justify-content-end align-items-center">
                    <Box className="w-100">
                        <InfiniteAutocompleteSelect
                            entityName="שם"
                            filterProp={'name'}
                            handleSelected={selectedValue => handleFilterChange('name', selectedValue)}
                            fetchEndpoint={'/user/suggestions'}
                            clearInput={currentFilter?.[1] === 'email'}
                        />
                    </Box>
                </Col>
                <Col md={12}>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>מזהה</th>
                            {/* clickable column for name */}
                            <th
                                onClick={() => handleSort('name')}
                                style={{cursor: 'pointer'}}
                            >
                                שם
                                {sortBy === 'name' ? (
                                    sortDirection === 1 ? (
                                        <ArrowUpwardIcon fontSize="small" style={{marginLeft: '4px'}}/>
                                    ) : (
                                        <ArrowDownwardIcon fontSize="small" style={{marginLeft: '4px'}}/>
                                    )
                                ) : (
                                    <SortIcon fontSize="small" style={{marginLeft: '4px', color: '#ccc'}}/>
                                )}
                            </th>
                            <th>טלפון</th>
                            <th>אימייל</th>
                            {/* clickable column for createdAt */}
                            <th
                                onClick={() => handleSort('createdAt')}
                                style={{cursor: 'pointer'}}
                            >
                                תאריך יצירה
                                {sortBy === 'createdAt' ? (
                                    sortDirection === 1 ? (
                                        <ArrowUpwardIcon fontSize="small" style={{marginLeft: '4px'}}/>
                                    ) : (
                                        <ArrowDownwardIcon fontSize="small" style={{marginLeft: '4px'}}/>
                                    )
                                ) : (
                                    <SortIcon fontSize="small" style={{marginLeft: '4px', color: '#ccc'}}/>
                                )}
                            </th>
                            <th>פעולות</th>
                        </tr>
                        </thead>
                        <tbody>
                        {users.map((user) => (
                            <tr key={user.id} onClick={() => openUserDetailsModal(user)}>
                                <td>{user.id}</td>
                                <td>{user.name}</td>
                                <td>{user.phone}</td>
                                <td>{user.email}</td>
                                <td>
                                    {new Date(user.createdAt).toLocaleString('he-IL', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </td>
                                <td>
                                    <Button
                                        className="me-2"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            openModal(user);
                                        }}
                                    >
                                        עריכה
                                    </Button>
                                    <Button
                                        variant="danger"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            openDeleteModal(user);
                                        }}
                                    >
                                        מחיקה
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <PaginationBar/>
                </Col>

                {/* Edit User Modal */}
                <Modal show={showModal} onHide={() => {
                    setShowModal(false);
                    formik.resetForm();
                }} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>עריכת משתמש</Modal.Title>
                    </Modal.Header>
                    <Form noValidate onSubmit={formik.handleSubmit}>
                        <Modal.Body>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="name">שם</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isInvalid={formik.touched.name && !!formik.errors.name}
                                    placeholder="הכנס שם"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.name}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="phone">טלפון</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    value={formik.values.phone || ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isInvalid={formik.touched.phone && !!formik.errors.phone}
                                    placeholder="הכנס טלפון"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.phone}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="email">אימייל</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isInvalid={formik.touched.email && !!formik.errors.email}
                                    placeholder="הכנס אימייל"
                                    disabled
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="field">תחום</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="field"
                                    name="field"
                                    value={formik.values.field || ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="הכנס תחום"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="clientType">סוג לקוח</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="clientType"
                                    name="clientType"
                                    value={formik.values.clientType || ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="הכנס סוג לקוח"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="companySize">גודל חברה</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="companySize"
                                    name="companySize"
                                    value={formik.values.companySize || 0}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="הכנס גודל חברה"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="websites">אתרים</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="websites"
                                    name="websites"
                                    value={formik.values.websites.join(', ')}
                                    onChange={(e) => handleWebsitesChange(e.target.value)}
                                    onBlur={formik.handleBlur}
                                    placeholder="הכנס אתרים (מופרדים בפסיק)"
                                    isInvalid={formik.touched.websites && !!formik.errors.websites}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.websites}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3 form-check">
                                <Form.Check
                                    type="checkbox"
                                    id="admin"
                                    name="admin"
                                    label="מנהל"
                                    checked={formik.values.admin}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                סגור
                            </Button>
                            <Button variant="primary" type="submit">
                                שמור
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

                {/* User Details Modal */}
                <Modal
                    show={showUserDetailsModal}
                    onHide={() => setShowUserDetailsModal(false)}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>פרטי משתמש - {selectedUser?.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>שם: {selectedUser?.name}</p>
                        <p>טלפון: {selectedUser?.phone || 'לא צוין'}</p>
                        <p>אימייל: {selectedUser?.email}</p>
                        <p>תחום: {selectedUser?.field || 'לא צוין'}</p>
                        <p>סוג לקוח: {selectedUser?.clientType || 'לא צוין'}</p>
                        <p>גודל חברה: {selectedUser?.companySize || 'לא צוין'}</p>
                        <p>
                            אתרים: {selectedUser?.websites.join(', ') || 'לא צוינו אתרים'}
                        </p>
                        <p>מנהל: {selectedUser?.admin ? 'כן' : 'לא'}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="outline-primary"
                            onClick={() => {
                                setShowUserDetailsModal(false);
                                setShowLinksModal(true);
                            }}
                        >
                            הענקת קישור
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                setShowUserDetailsModal(false);
                                setShowPackagesModal(true);
                            }}
                        >
                            הענקת חבילה
                        </Button>
                        <Button
                            variant="warning"
                            onClick={() => {
                                setShowUserDetailsModal(false);
                                setShowResetPasswordModal(true);
                            }}
                        >
                            החלפת סיסמא
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={() => setShowUserDetailsModal(false)}
                        >
                            סגור
                        </Button>
                    </Modal.Footer>
                </Modal>

                <AttachPackageToUserModal
                    userId={selectedUser ? selectedUser.id : ''}
                    show={showPackagesModal}
                    onHide={() => setShowPackagesModal(false)}
                />
                <AttachLinkToUserModal
                    userId={selectedUser ? selectedUser.id : ''}
                    show={showLinksModal}
                    onHide={() => setShowLinksModal(false)}
                />

                {/* Reset Password Modal */}
                <Modal
                    show={showResetPasswordModal}
                    onHide={() => setShowResetPasswordModal(false)}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>החלפת סיסמא</Modal.Title>
                    </Modal.Header>
                    <Form noValidate onSubmit={resetPasswordFormik.handleSubmit}>
                        <Modal.Body>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="newPassword">סיסמה חדשה</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type={showNewPassword ? "text" : "password"}
                                        id="newPassword"
                                        name="newPassword"
                                        value={resetPasswordFormik.values.newPassword}
                                        onChange={resetPasswordFormik.handleChange}
                                        onBlur={resetPasswordFormik.handleBlur}
                                        isInvalid={resetPasswordFormik.touched.newPassword && !!resetPasswordFormik.errors.newPassword}
                                        placeholder="הכנס סיסמה חדשה"
                                    />
                                    <InputGroup.Text style={{ cursor: 'pointer' }} onClick={() => setShowNewPassword(!showNewPassword)}>
                                        {showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </InputGroup.Text>
                                </InputGroup>
                                <Form.Control.Feedback type="invalid">
                                    {resetPasswordFormik.errors.newPassword}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="confirmPassword">אימות סיסמה</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type={showConfirmPassword ? "text" : "password"}
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        value={resetPasswordFormik.values.confirmPassword}
                                        onChange={resetPasswordFormik.handleChange}
                                        onBlur={resetPasswordFormik.handleBlur}
                                        isInvalid={resetPasswordFormik.touched.confirmPassword && !!resetPasswordFormik.errors.confirmPassword}
                                        placeholder="הכנס סיסמה שנית"
                                    />
                                    <InputGroup.Text style={{ cursor: 'pointer' }} onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                        {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </InputGroup.Text>
                                </InputGroup>
                                <Form.Control.Feedback type="invalid">
                                    {resetPasswordFormik.errors.confirmPassword}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowResetPasswordModal(false)}>
                                סגור
                            </Button>
                            <Button variant="primary" type="submit">
                                החלף סיסמא
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

                <ConfirmationModal
                    show={deleteModalShow}
                    onClose={() => setDeleteModalShow(false)}
                    onConfirm={handleDeleteConfirmation}
                    title="אישור מחיקה"
                    message="האם אתה בטוח שאתה רוצה למחוק את המשתמש?"
                />
            </Row>
        </div>
    );
};

export default AdminUsers;
