import React, {useEffect, useState} from 'react';
import SkeletonLoader from '../SkeletonLoader';
import {Container, Row} from "react-bootstrap";
import {AppDispatch} from "../../utils/store";
import {useDispatch} from "react-redux";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Link from "../../models/Link";
import SiteItem from "./SiteItem";
import {styled} from "@mui/material/styles";
import {useApiService} from "../../context/ApiServiceContext";
import {Typography} from "@mui/material";
import BottomBorder from "../BottomBorder";
import {ArrowLeft, ArrowRight} from "../../views/arrow";

const StyledSlider = styled(Slider)(({theme}) => ({
    '& .slick-list': {
        paddingBottom: '20px',
    }
}));

const SiteList: React.FC = () => {
    const apiService = useApiService();
    const dispatch: AppDispatch = useDispatch();
    const [packages, setPackages] = useState<Link[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        apiService.api.get('/link/list?exampleSite=true')
            .then(response => {
                setPackages(response.data)
                setIsLoading(false);
            });
    }, []);

    const settings = {
        dots: false,
        infinite: packages.length > 4,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        slidesToShow: 4, // show up to 4 items
        slidesToScroll: 4, // scroll 4 items at a time
        arrows: packages.length > 4,
        swipe: true,
        rtl: true,
        nextArrow: <ArrowRight/>,
        prevArrow: <ArrowLeft/>,
        responsive: [
            {
                breakpoint: 1400, // md breakpoint
                settings: {
                    infinite: packages.length > 3,
                    slidesToShow: 3,
                    slidesToScroll: 3
                },
            },
            {
                breakpoint: 1024, // md breakpoint
                settings: {
                    infinite: packages.length > 2,
                    slidesToShow: 2,
                    slidesToScroll: 2
                },
            },
            {
                breakpoint: 768, // xs breakpoint
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // arrows: false,
                },
            },
            // You can add more breakpoints as needed
        ],
    };

    return (
        <>
            {isLoading ? (
                <>
                    <Row>
                        <Typography variant="h2">דוגמאות לאתרים</Typography>
                        <BottomBorder width={69}></BottomBorder>
                    </Row>
                    <Row>
                        <SkeletonLoader amount={2}/>
                    </Row>
                </>
            ) : (packages.length > 0 && <Container className="pb-5">
                    <Row>
                        <Typography variant="h2">דוגמאות לאתרים</Typography>
                        <BottomBorder width={69}></BottomBorder>
                    </Row>
                    <Row style={{marginTop: "42px"}}>
                        <StyledSlider {...settings}>
                            {packages.map((pack, index) => (
                                <div key={index} dir='rtl'>
                                    <SiteItem {...pack}/>
                                </div>
                            ))}
                        </StyledSlider>
                    </Row>
                </Container>
            )}
        </>
    );
};

export default SiteList;