import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Form, Modal, Table} from 'react-bootstrap';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {toast} from 'react-toastify';
import {useLocation, useNavigate} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import CustomSelect from './views/CustomSelect';
import {fetchOrderById, selectOrderById, updateOrderTask} from '../../utils/ordersSlice';
import {
    AdminOrderTaskUpdateLinkingSiteBody,
    EditableOrder,
    EditableOrderTask,
    OrderCategory,
    OrderTaskBody,
    OrderTaskStatus
} from '../../models/Order';
import {AppDispatch, RootState} from '../../utils/store';
import {formatISO} from 'date-fns';
import {trimToHostname} from '../../utils/utils';
import Spinner from '../../views/Spinner';
import AnalyticsService, {AnalyticsEventName} from '../../services/AnalyticsService';
import {useApiService} from '../../context/ApiServiceContext';
import Link, {SiteType} from '../../models/Link';
import he from 'date-fns/locale/he';
import {CircularProgress} from "@mui/material";

registerLocale("he", he);

const useStyles = makeStyles((theme) => ({
    modal: {
        '& .modal-body': {
            maxHeight: '66vh',
            overflowY: 'auto'
        },
        '& .modal-dialog': {
            width: '95%',
            maxWidth: '95%',
            margin: '100px auto auto',
            minHeight: "calc(100% - 95px - var(--bs-modal-margin) * 2)",
            maxHeight: "calc(100% - 95px - var(--bs-modal-margin) * 2)",
            [theme.breakpoints.down('md')]: {
                width: '100%',
                maxWidth: '100%'
            }
        }
    },
    status: {
        padding: "4px 10px",
        borderRadius: "5px",
        display: "inline-flex",
        height: "38px",
        alignItems: "center",
        justifyContent: "center",
    },
    tdBg: {
        '& td': {
            backgroundColor: "#d8d5fb30"
        }
    },
}));

interface Props {
    handleClose: () => void;
    open: boolean;
    orderId: string | null;
    taskId?: string | null;
    adminLinkingSiteUpdateOnly?: boolean;
}

const OrderDetailsTableModal: React.FC<Props> = ({
                                                     handleClose,
                                                     open,
                                                     orderId,
                                                     taskId,
                                                     adminLinkingSiteUpdateOnly = false
                                                 }) => {
    const classes = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const apiService = useApiService();
    const analyticsService = AnalyticsService.getInstance();
    const orderFromStore = useSelector((state: RootState) =>
        selectOrderById(state, orderId ?? "")
    );
    const [editableOrder, setEditableOrder] = useState<EditableOrder | undefined>(
        orderFromStore
            ? {
                ...orderFromStore,
                tasks: orderFromStore.tasks.map((x) => ({
                    ...x,
                    linkingSiteId: x.linkingSite?.id,
                    linkingSiteHostname: x.linkingSite?.hostname
                }))
            }
            : undefined
    );
    const [validationStates, setValidationStates] = useState<{ [key: string]: boolean }>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSendingOrder, setSendingOrder] = useState<boolean>(false);
    const [isFileUploading, setFileUploading] = useState<boolean>(false);
    const [categories, setCategories] = useState<OrderCategory[]>([]);
    const isFirstLoad = useRef(true);

    useEffect(() => {
        if (!orderFromStore && orderId) {
            setIsLoading(true);
            dispatch(fetchOrderById(orderId)).catch(() => {
                setEditableOrder(undefined);
                setIsLoading(false);
            });
        }
    }, [orderId, orderFromStore, dispatch]);

    useEffect(() => {
        if (orderFromStore) {
            let tasks = orderFromStore.tasks.map((x) => ({
                ...x,
                linkingSiteId: x.linkingSite?.id,
                linkingSiteHostname: x.linkingSite?.hostname,
                scheduledTime: formatISO(
                    x['scheduledTime'] ? new Date(x['scheduledTime']) : getMinDate(),
                    {representation: 'complete'}
                )
            }));
            if (taskId) {
                tasks = tasks.filter((task) => task.id === taskId);
            }
            const order = {...orderFromStore, tasks};
            if (isFirstLoad.current) {
                isFirstLoad.current = false;
            }
            setUniqueCategories(order);
            setEditableOrder(order);
            setIsLoading(false);
        }
    }, [orderFromStore]);

    function setUniqueCategories(order: EditableOrder) {
        const uniqueCategoriesMap = new Map();
        order.tasks.forEach((x) => {
            if (x.category && !uniqueCategoriesMap.has(x.category.id)) {
                uniqueCategoriesMap.set(x.category.id, x.category);
            }
            if (x.subCategories) {
                x.subCategories.forEach((subCategory) => {
                    if (!uniqueCategoriesMap.has(subCategory.id)) {
                        uniqueCategoriesMap.set(subCategory.id, subCategory);
                    }
                });
            }
        });
        setCategories(Array.from(uniqueCategoriesMap.values()));
    }

    function getMinDate() {
        const minDate = new Date();
        minDate.setDate(minDate.getDate() + 7);
        return minDate;
    }

    // ---------------- Validation Functions ----------------
    const validateWebsite = (url: string, index: number, field: string): boolean => {
        const pattern = new RegExp(
            '^(https?:\\/\\/)?' +
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
            '((\\d{1,3}\\.){3}\\d{1,3}))' +
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+=]*)*' +
            '(\\?[;&a-z\\d%_.~+=-]*)?' +
            '(\\#[-a-z\\d_]*)?$',
            'i'
        );
        const isValid = pattern.test(url);
        setValidationStates((prev) => ({...prev, [`${index}-${field}`]: isValid}));
        return isValid;
    };

    const validateInput = (value: string, index: number, field: string): boolean => {
        const isValid = value.trim().length > 0;
        setValidationStates((prev) => ({...prev, [`${index}-${field}`]: isValid}));
        return isValid;
    };

    const validateDate = (date: Date | null, index: number): boolean => {
        const isValid = date !== null;
        setValidationStates((prev) => ({...prev, [`${index}-scheduledTime`]: isValid}));
        return isValid;
    };

    enum ValidatorType {
        WEBSITE,
        TEXT
    }

    type FieldType = { key: string; type: ValidatorType };
    const FIELD: { [key: string]: FieldType } = {
        LINKING_SITE: {key: 'linkingSiteId', type: ValidatorType.TEXT},
        ANCHOR: {key: 'anchor', type: ValidatorType.TEXT},
        ANCHOR_LINK: {key: 'anchorLink', type: ValidatorType.WEBSITE},
        SECOND_ANCHOR: {key: 'secondAnchor', type: ValidatorType.TEXT},
        SECOND_ANCHOR_LINK: {key: 'secondAnchorLink', type: ValidatorType.WEBSITE},
        SCHEDULED_TIME: {key: 'scheduledTime', type: ValidatorType.TEXT}
    };

    const requiredFields = [
        FIELD.LINKING_SITE,
        FIELD.ANCHOR,
        FIELD.ANCHOR_LINK,
        FIELD.SCHEDULED_TIME
    ];

    const handleInputChange = (index: number, field: FieldType, value: string) => {
        const updatedTasks = [...(editableOrder?.tasks ?? [])];
        updatedTasks[index] = {...updatedTasks[index], [field.key]: value};
        if (editableOrder) {
            setEditableOrder({...editableOrder, tasks: updatedTasks});
        }
        validate(index, field, value);
    };

    const validate = (index: number, field: FieldType, value: string) => {
        if (field.type === ValidatorType.WEBSITE) {
            return validateWebsite(value, index, field.key);
        } else {
            return validateInput(value, index, field.key);
        }
    };

    const handleLinkingSiteChange = (index: number, value: Link) => {
        const updatedTasks = [...(editableOrder?.tasks ?? [])];
        updatedTasks[index] = {
            ...updatedTasks[index],
            linkingSiteHostname: value.website,
            linkingSiteId: value.id
        };
        if (editableOrder) {
            setEditableOrder({...editableOrder, tasks: updatedTasks});
        }
        setValidationStates((prev) => ({
            ...prev,
            [`${index}-${FIELD.LINKING_SITE.key}`]: value.website.length > 0
        }));
    };

    // ---------------- Replace Radio Buttons with Dropdown ----------------
    const handlePreciseAnchorChange = (index: number, value: string) => {
        const isPrecise = value === "true";
        const updatedTasks = [...(editableOrder?.tasks ?? [])];
        updatedTasks[index] = {...updatedTasks[index], preciseAnchor: isPrecise};
        if (editableOrder) {
            setEditableOrder({...editableOrder, tasks: updatedTasks});
        }
    };

    const handleDateChange = (index: number, field: FieldType, date: Date | null) => {
        const updatedTasks = [...(editableOrder?.tasks ?? [])];
        updatedTasks[index] = {
            ...updatedTasks[index],
            [field.key]: formatISO(date ?? getMinDate(), {representation: 'complete'})
        };
        if (editableOrder) {
            setEditableOrder({...editableOrder, tasks: updatedTasks});
        }
    };

    const handleFileChange = (index: number, taskId: string, file: File | null) => {
        if (file) {
            setFileUploading(true);
            const formData = new FormData();
            formData.append('file', file);
            apiService.api
                .post(`/order/${orderId}/task/${taskId}/file`, formData, {
                    headers: {'Content-Type': 'multipart/form-data'}
                })
                .then(() => {
                    setValidationStates((prev) => ({...prev, [`${index}-file`]: true}));
                })
                .catch((error) => {
                    console.log(error);
                    setValidationStates((prev) => ({...prev, [`${index}-file`]: false}));
                })
                .finally(() => {
                    setFileUploading(false);
                });
        }
    };

    const openPresignedUrl = (taskId: string) => {
        apiService.api
            .get(`/order/${orderId}/task/${taskId}/file`)
            .then((response) => {
                window.open(response.data.url, '_blank');
            })
            .catch((error) => {
                toast.error("נתקלנו בבעיה בפתיחת הקישור");
            });
    };

    const isFormValid = (index: number): boolean => {
        let isValid = true;
        const task = editableOrder?.tasks[index];
        const vStates: any = {};
        requiredFields.forEach((field) => {
            const key = `${index}-${field.key}`;
            if (task && field.key in task && validate(index, field, task[field.key as keyof EditableOrderTask] as string)) {
                vStates[key] = true;
            }
            if (
                validationStates[key] !== true &&
                !vStates[key] &&
                !(editableOrder?.itemType === 'link' && field === FIELD.LINKING_SITE)
            ) {
                vStates[key] = false;
                isValid = false;
            }
        });
        if (
            task?.linkingSite?.type === SiteType.PREMIUM &&
            task?.usersContent &&
            !task?.documentUrl &&
            !validationStates[`${index}-file`]
        ) {
            vStates[`${index}-file`] = false;
            isValid = false;
        }
        setValidationStates((prev) => ({...prev, ...vStates}));
        return isValid;
    };

    const initTask = (orderId: string, taskId: string, payload: OrderTaskBody) => {
        return apiService.api.post(`/order/${orderId}/task/${taskId}`, payload);
    };

    const updateTask = (orderId: string, taskId: string, payload: OrderTaskBody) => {
        return apiService.api.put(`/order/${orderId}/task/${taskId}`, payload);
    };

    const adminUpdateLinkingSiteInTask = (
        orderId: string,
        taskId: string,
        payload: AdminOrderTaskUpdateLinkingSiteBody
    ) => {
        return apiService.api.put(`/admin/order/${orderId}/task/${taskId}`, payload);
    };

    const handleInitTask = async (index: number) => {
        try {
            const task = editableOrder?.tasks[index];
            if (task && isFormValid(index)) {
                setSendingOrder(true);
                const body: OrderTaskBody = {
                    anchor: task.anchor,
                    anchorLink: task.anchorLink,
                    linkingSiteId: task.linkingSiteId,
                    scheduledTime: task.scheduledTime,
                    preciseAnchor: task.preciseAnchor ?? true
                };
                if (orderFromStore?.allowSecondAnchor) {
                    if (task.secondAnchor) {
                        body['secondAnchor'] = task.secondAnchor;
                    }
                    if (task.secondAnchorLink) {
                        body['secondAnchorLink'] = task.secondAnchorLink;
                    }
                }
                const newTask = await initTask(editableOrder.id, task.id, body);
                dispatch(updateOrderTask([orderFromStore!, newTask.data]));
                analyticsService.sendCustomEvent(AnalyticsEventName.LinkInitiated, {
                    packageSize: editableOrder.tasks.length,
                    price: editableOrder.totalPrice,
                    linkType: task.siteType,
                    fulfilledLinks:
                        editableOrder.tasks.filter((t) => t.status !== OrderTaskStatus.PENDING).length + 1
                });
                toast.success('ההזמנה נשלחה בהצלחה');
            }
        } catch (e) {
            toast.error("נתקלנו בבעיה בעת עדכון המשימה, נסו מאוחר יותר");
        } finally {
            setSendingOrder(false);
        }
    };

    const handleUpdateTask = async (index: number) => {
        try {
            const task = editableOrder?.tasks[index];
            if (task && isFormValid(index)) {
                setSendingOrder(true);
                const body: OrderTaskBody = {
                    anchor: task.anchor,
                    anchorLink: task.anchorLink,
                    linkingSiteId: task.linkingSiteId,
                    scheduledTime: task.scheduledTime,
                    preciseAnchor: task.preciseAnchor ?? false
                };
                if (orderFromStore?.allowSecondAnchor) {
                    if (task.secondAnchor) {
                        body['secondAnchor'] = task.secondAnchor;
                    }
                    if (task.secondAnchorLink) {
                        body['secondAnchorLink'] = task.secondAnchorLink;
                    }
                }
                const newTask = await updateTask(editableOrder.id, task.id, body);
                dispatch(updateOrderTask([orderFromStore!, newTask.data]));
                toast.success('ההזמנה עודכנה בהצלחה');
            }
        } catch (e) {
            toast.error("נתקלנו בבעיה בעת עדכון המשימה, נסו מאוחר יותר");
        } finally {
            setSendingOrder(false);
        }
    };

    const handleUpdateLinkingSiteTask = async (index: number) => {
        try {
            const task = editableOrder?.tasks[index];
            if (task && task.linkingSiteId) {
                setSendingOrder(true);
                const body: AdminOrderTaskUpdateLinkingSiteBody = {
                    linkingSiteId: task.linkingSiteId
                };
                const newTask = await adminUpdateLinkingSiteInTask(editableOrder.id, task.id, body);
                dispatch(updateOrderTask([orderFromStore!, newTask.data]));
                toast.success('ההזמנה עודכנה בהצלחה');
            }
        } catch (e) {
            toast.error("נתקלנו בבעיה בעת עדכון המשימה, נסו מאוחר יותר");
        } finally {
            setSendingOrder(false);
        }
    };

    const handleUpdate = (index: number) => {
        if (adminLinkingSiteUpdateOnly) {
            handleUpdateLinkingSiteTask(index);
        } else {
            handleUpdateTask(index);
        }
    };

    const handleCloseAndCancel = () => {
        setEditableOrder(
            orderFromStore
                ? {
                    ...orderFromStore,
                    tasks: orderFromStore.tasks.map((x) => ({
                        ...x,
                        linkingSiteId: x.linkingSite?.id,
                        linkingSiteHostname: x.linkingSite?.hostname
                    }))
                }
                : undefined
        );
        handleClose();
        const currentPath = location.pathname;
        navigate(`${currentPath}`, {replace: true});
    };

    if (isLoading) {
        return (
            <Modal show={open} onHide={handleCloseAndCancel} centered>
                <Modal.Header closeButton>
                    <Modal.Title>טוען את ההזמנה</Modal.Title>
                    {isLoading && <Spinner size={35}/>}
                </Modal.Header>
                <Modal.Body>
                    <div>זה יקח ממש עוד רגע</div>
                </Modal.Body>
            </Modal>
        );
    } else if (!editableOrder) {
        return (
            <Modal show={open} onHide={handleCloseAndCancel} centered>
                <Modal.Header closeButton>
                    <Modal.Title>יש לנו בעיה</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>לא נמצאו פרטי הזמנה.</div>
                </Modal.Body>
            </Modal>
        );
    }

    // Determine if any task has an available action.
    const hasActions =
        editableOrder.tasks.some(
            (task) =>
                task.status === OrderTaskStatus.PENDING ||
                task.status === OrderTaskStatus.PENDING_DATA ||
                adminLinkingSiteUpdateOnly
        ) || false;

    const hasFileLink = editableOrder.tasks.some(
        (task) =>
            (task.linkingSite?.type === SiteType.PREMIUM &&
                ([OrderTaskStatus.PENDING, OrderTaskStatus.PENDING_DATA].includes(task.status) ||
                    task.documentUrl)) ||
            task.articleUrl
    );

    function getStatusDisplay(status: string) {
        if (status === OrderTaskStatus.COMPLETED) {
            return 'באוויר'
        } else if (status === OrderTaskStatus.SENT) {
            return 'נשלח לביצוע'
        } else if (status === OrderTaskStatus.PENDING) {
            return 'ממתין'
        } else if (status === OrderTaskStatus.PENDING_DATA) {
            return 'ממתין לחומרים'
        } else {
            return 'בעבודה'
        }
    }

    function getStatusColor(status?: OrderTaskStatus) {
        switch (status) {
            case OrderTaskStatus.PENDING:
                return "#D8D5FB"
            case OrderTaskStatus.IN_PROGRESS:
            case OrderTaskStatus.PENDING_DATA:
            case OrderTaskStatus.SENT:
                return "#EDDC9F"
            case OrderTaskStatus.COMPLETED:
                return "#9feda9"
        }
    }

    return (
        <Modal show={open} onHide={handleCloseAndCancel} centered className={classes.modal}>
            <Modal.Header closeButton>
                <Modal.Title>הזמנה</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Table bordered hover responsive>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>אנקור</th>
                            <th>קישור לאנקור (URL שלך)</th>
                            <th>אתר מקשר</th>
                            <th style={{width: '130px'}}>סוג אנקור</th>
                            <th style={{width: '130px'}}>תאריך הזמנה</th>
                            <th style={{width: '130px'}}>תאריך ביצוע</th>
                            <th style={{width: '100px'}}>סטטוס</th>
                            {hasFileLink && <th>העלאת קובץ</th>}
                            {hasActions && <th>פעולות</th>}
                        </tr>
                        </thead>
                        <tbody>
                        {editableOrder.tasks.map((task, index) => (
                            <tr key={index} className={index % 2 ? classes.tdBg : ''}>
                                <td>{index + 1}</td>
                                <td>
                                    {orderFromStore?.allowSecondAnchor ? (
                                        <>
                                            <Form.Group controlId={`anchor-1-${index}`}>
                                                <Form.Control
                                                    type="text"
                                                    value={task.anchor || ''}
                                                    onChange={(e) =>
                                                        handleInputChange(index, FIELD.ANCHOR, e.target.value)
                                                    }
                                                    disabled={![OrderTaskStatus.PENDING, OrderTaskStatus.PENDING_DATA].includes(task.status)}
                                                    isInvalid={validationStates[`${index}-${FIELD.ANCHOR.key}`] === false}
                                                    required
                                                    placeholder="אנקור ראשון"
                                                />
                                            </Form.Group>
                                            <Form.Group controlId={`anchor-2-${index}`} className="mt-2">
                                                <Form.Control
                                                    type="text"
                                                    value={task.secondAnchor || ''}
                                                    onChange={(e) =>
                                                        handleInputChange(index, FIELD.SECOND_ANCHOR, e.target.value)
                                                    }
                                                    disabled={![OrderTaskStatus.PENDING, OrderTaskStatus.PENDING_DATA].includes(task.status)}
                                                    isInvalid={validationStates[`${index}-${FIELD.SECOND_ANCHOR.key}`] === false}
                                                    required
                                                    placeholder="אנקור שני"
                                                />
                                            </Form.Group>
                                        </>
                                    ) : (
                                        <Form.Group controlId={`anchor-${index}`}>
                                            <Form.Control
                                                type="text"
                                                value={task.anchor || ''}
                                                onChange={(e) =>
                                                    handleInputChange(index, FIELD.ANCHOR, e.target.value)
                                                }
                                                disabled={![OrderTaskStatus.PENDING, OrderTaskStatus.PENDING_DATA].includes(task.status)}
                                                isInvalid={validationStates[`${index}-${FIELD.ANCHOR.key}`] === false}
                                                required
                                                placeholder="אנקור"
                                            />
                                        </Form.Group>
                                    )}
                                </td>
                                <td>
                                    {orderFromStore?.allowSecondAnchor ? (
                                        <>
                                            <Form.Group controlId={`anchorLink-1-${index}`}>
                                                <Form.Control
                                                    type="text"
                                                    value={task.anchorLink || ''}
                                                    onChange={(e) =>
                                                        handleInputChange(index, FIELD.ANCHOR_LINK, e.target.value)
                                                    }
                                                    disabled={![OrderTaskStatus.PENDING, OrderTaskStatus.PENDING_DATA].includes(task.status)}
                                                    isInvalid={validationStates[`${index}-${FIELD.ANCHOR_LINK.key}`] === false}
                                                    placeholder="קישור לאנקור ראשון"
                                                    style={{
                                                        unicodeBidi: "plaintext"
                                                    }}
                                                    required
                                                />
                                            </Form.Group>
                                            <Form.Group controlId={`anchorLink-2-${index}`} className="mt-2">
                                                <Form.Control
                                                    type="text"
                                                    value={task.secondAnchorLink || ''}
                                                    onChange={(e) =>
                                                        handleInputChange(index, FIELD.SECOND_ANCHOR_LINK, e.target.value)
                                                    }
                                                    disabled={![OrderTaskStatus.PENDING, OrderTaskStatus.PENDING_DATA].includes(task.status)}
                                                    isInvalid={validationStates[`${index}-${FIELD.SECOND_ANCHOR_LINK.key}`] === false}
                                                    placeholder="קישור לאנקור שני"
                                                    style={{
                                                        unicodeBidi: "plaintext"
                                                    }}
                                                />
                                            </Form.Group>
                                        </>
                                    ) : (
                                        <Form.Group controlId={`anchorLink-${index}`}>
                                            <Form.Control
                                                type="text"
                                                value={task.anchorLink || ''}
                                                onChange={(e) =>
                                                    handleInputChange(index, FIELD.ANCHOR_LINK, e.target.value)
                                                }
                                                disabled={![OrderTaskStatus.PENDING, OrderTaskStatus.PENDING_DATA].includes(task.status)}
                                                isInvalid={validationStates[`${index}-${FIELD.ANCHOR_LINK.key}`] === false}
                                                required
                                                placeholder="קישור לאנקור"
                                                style={{
                                                    unicodeBidi: "plaintext"
                                                }}
                                            />
                                        </Form.Group>
                                    )}
                                </td>
                                <td>
                                    <Form.Group controlId={`linkingSite-${index}`}>
                                        {editableOrder.itemType === 'package' && task.siteType != SiteType.PREMIUM ? (
                                            <CustomSelect
                                                value={task.linkingSiteHostname || ''}
                                                onChange={(value) => handleLinkingSiteChange(index, value)}
                                                disabled={
                                                    ![OrderTaskStatus.PENDING, OrderTaskStatus.PENDING_DATA].includes(task.status) &&
                                                    !adminLinkingSiteUpdateOnly
                                                }
                                                isInvalid={validationStates[`${index}-${FIELD.LINKING_SITE.key}`] === false}
                                                siteType={editableOrder.tasks[0].siteType}
                                                anchor={task.anchorLink || ''}
                                                required
                                            />
                                        ) : adminLinkingSiteUpdateOnly ? (
                                            <>
                                                <Form.Label>אתר מקשר</Form.Label>
                                                <CustomSelect
                                                    value={task.linkingSiteHostname || ''}
                                                    onChange={(value) => handleLinkingSiteChange(index, value)}
                                                    disabled={false}
                                                    isInvalid={validationStates[`${index}-${FIELD.LINKING_SITE.key}`] === false}
                                                    required
                                                />
                                            </>
                                        ) : (
                                            <Form.Control
                                                dir="ltr"
                                                type="text"
                                                value={trimToHostname(task.linkingSiteHostname) || ''}
                                                disabled={true}
                                                isInvalid={validationStates[`${index}-${FIELD.LINKING_SITE.key}`] === false}
                                                required
                                            />
                                        )}
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group controlId={`preciseAnchor-${index}`}>
                                        <Form.Select
                                            value={task.preciseAnchor ? "true" : "false"}
                                            onChange={(e) => handlePreciseAnchorChange(index, e.target.value)}
                                            disabled={![OrderTaskStatus.PENDING, OrderTaskStatus.PENDING_DATA].includes(task.status)}
                                        >
                                            <option value="false">תתאימו לי</option>
                                            <option value="true">מדויק</option>
                                        </Form.Select>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group controlId={`orderTime-${index}`}>
                                        <Form.Control
                                            type="text"
                                            value={
                                                task.orderTime
                                                    ? new Date(task.orderTime).toLocaleDateString('he-IL')
                                                    : '-'
                                            }
                                            disabled
                                        />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group controlId={`scheduledTime-${index}`}>
                                        <DatePicker
                                            className="form-control"
                                            selected={task.scheduledTime ? new Date(task.scheduledTime) : null}
                                            onChange={(date) => {
                                                handleDateChange(index, FIELD.SCHEDULED_TIME, date);
                                                validateDate(date, index);
                                            }}
                                            locale="he"
                                            dateFormat="dd/MM/yyyy"
                                            minDate={getMinDate()}
                                            required
                                            disabled={![OrderTaskStatus.PENDING, OrderTaskStatus.PENDING_DATA].includes(task.status)}
                                        />
                                    </Form.Group>
                                </td>
                                <td>
                                                                <span className={`${classes.status} text-nowrap`}
                                                                      style={{
                                                                          backgroundColor: getStatusColor(task.status)
                                                                      }}>
                                                                    {getStatusDisplay(task.status)}
                                                                </span>
                                </td>
                                {hasFileLink && <td>
                                    {task.linkingSite?.type === SiteType.PREMIUM &&
                                        ([OrderTaskStatus.PENDING, OrderTaskStatus.PENDING_DATA].includes(task.status) ? (
                                            <Form.Group controlId={`file-${index}`}>
                                                <div className="d-flex align-items-center">
                                                    <input
                                                        type="file"
                                                        id={`fileInput-${index}`}
                                                        hidden
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                            handleFileChange(
                                                                index,
                                                                task.id,
                                                                e.target.files ? e.target.files[0] : null
                                                            )
                                                        }
                                                    />

                                                    {/* Button with Dynamic Text */}
                                                    <Button
                                                        className="simple-button medium white auto"
                                                        onClick={() => document.getElementById(`fileInput-${index}`)?.click()}
                                                        disabled={isFileUploading} // Disable while uploading
                                                    >
                                                        {isFileUploading
                                                            ? "מעלה את הקובץ..."
                                                            : validationStates[`${index}-file`] === true
                                                                ? "קובץ הועלה. החלף"
                                                                : "בחר קובץ"}
                                                    </Button>

                                                    {/* Uploading Indicator */}
                                                    {isFileUploading && (
                                                        <>
                                                            <CircularProgress className="ms-3" color="secondary"
                                                                              size={13}/>
                                                        </>
                                                    )}
                                                </div>

                                                {/* Validation Error */}
                                                {validationStates[`${index}-file`] === false && (
                                                    <Form.Control.Feedback type="invalid" className="d-block mt-2">
                                                        חובה להכניס קובץ
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        ) : task.documentUrl ? (
                                            <Button variant="text" onClick={() => openPresignedUrl(task.id)}>
                                                פתח קובץ
                                            </Button>
                                        ) : null)}
                                    {task.articleUrl && (
                                        <Button
                                            className="simple-button medium blue mt-2"
                                            onClick={() => window.open(task.articleUrl, '_blank')}
                                        >
                                            קישור למאמר
                                        </Button>
                                    )}
                                </td>}
                                {hasActions && (
                                    <td>
                                        {task.status === OrderTaskStatus.PENDING && (
                                            <Button
                                                className="simple-button medium blue"
                                                disabled={isSendingOrder || isFileUploading}
                                                onClick={() => handleInitTask(index)}
                                            >
                                                שלח הזמנה
                                            </Button>
                                        )}
                                        {(task.status === OrderTaskStatus.PENDING_DATA || adminLinkingSiteUpdateOnly) && (
                                            <Button
                                                className="simple-button medium blue"
                                                disabled={isSendingOrder || isFileUploading}
                                                onClick={() => handleUpdate(index)}
                                            >
                                                עדכן הזמנה
                                            </Button>
                                        )}
                                        {isSendingOrder && <Spinner size={30}/>}
                                    </td>
                                )}
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outlined" color="secondary" onClick={handleCloseAndCancel}>
                    סגור
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default OrderDetailsTableModal;
