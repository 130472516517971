import React, {useState} from "react";
import {Slider, Typography, Box} from "@mui/material";
import {makeStyles} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    title: {
        marginTop:"-25px!important",
        [theme.breakpoints.down('sm')]: {
            marginTop:"unset!important",
        }
    }
}));

interface PriceRangeFilterProps {
    minPrice: number;
    maxPrice: number;
    onChange: (value: [number, number]) => void;
    color: string;
}

const PriceRangeFilter: React.FC<PriceRangeFilterProps> = ({minPrice, maxPrice, onChange, color}) => {
    const classes = useStyles({});

    const [priceRange, setPriceRange] = useState<number[]>([minPrice, maxPrice]);

    const handleChange = (_event: Event, newValue: number | number[]) => {
        setPriceRange(newValue as number[]);
        onChange(newValue as [number, number]);
    };

    return (
        <Box sx={{width: '100%', textAlign: "center"}}>
            <Typography variant="subtitle1" textAlign="right" className={classes.title}>סנן לפי מחיר:</Typography>
            <Slider
                value={priceRange}
                onChange={handleChange}
                min={minPrice}
                max={maxPrice}
                valueLabelDisplay="auto"
                sx={{
                    direction: 'rtl',
                    color, // Yellow color
                    "& .MuiSlider-thumb": {
                        backgroundColor: color, // Yellow thumb
                        marginRight: '-20px'
                    },
                    "& .MuiSlider-track": {
                        backgroundColor: "gray", // Track color
                    },
                }}
            />
            <Typography>
                ₪{priceRange[0].toFixed(2)} - ₪{priceRange[1].toFixed(2)}
            </Typography>
        </Box>
    );
};

export default PriceRangeFilter;
