// AppLayout.tsx
import React, {useEffect, useState} from 'react';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import NavBar from "./NavBar";
import LinkRegoBoost from "./links/LinkBoost";
import LinkPremium from "./links/LinkPremium";
import LinkSuperRego from "./links/LinkSuperRego";
import OrderListModal from "./modals/OrderListModal";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../utils/store";
import {hideAllModals, hideModal, Modals, showModal} from "../utils/modalSlice";
import Main from "./Main";
import {Modal} from "react-bootstrap";
import Videos from "./Videos";
import Legal from "./Legal";
import {useMediaQuery, useTheme} from "@mui/material";
import Recommendations from "./Recommendations";
import LegalDocument from "./LegalDocument";
import Footer from "./Footer";
import DynamicPages from "./DynamicPages";
import HelperVideoModal from "./modals/HelperVideoModal";
import OrderDetailsMobileModal from "./modals/OrderDetailsMobileModal";
import {OrderListInitiator} from "../utils/types";
import OrderDetailsDesktopModal from "./modals/OrderDetailsDesktopModal";
import Spinner from "../views/Spinner";

const AppLayout: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(768));

    const [orderId, setOrderId] = useState<string | null>(null);
    const [initiator, setInitiator] = useState<OrderListInitiator | null>(null);

    const isOrderListModalVisible = useSelector((state: RootState) => state.modals[Modals.ORDER_LIST]);
    const isOpenOrdersListModalVisible = useSelector((state: RootState) => state.modals[Modals.OPEN_ORDER_LIST]);
    const isOrderDetailsModalVisible = useSelector((state: RootState) => state.modals[Modals.ORDER_DETAILS]);
    const isNavigatingToPaymentModalVisible = useSelector((state: RootState) => state.modals[Modals.MOVING_TO_PAYMENT]);
    const isHelperVideoModalVisible = useSelector((state: RootState) => state.modals[Modals.HELPER_VIDEO]);

    const modalStates = useSelector((state: RootState) => state.modals);

    const ROUTER_PAGES = [
        {path: "", element: <Main/>},
        {path: "boost", element: <LinkRegoBoost/>},
        {path: "super", element: <LinkSuperRego/>},
        {path: "premium", element: <LinkPremium/>},
        {path: "videos", element: <Videos/>},
        {path: "legal", element: <Legal/>},
        {path: "legal/:legalPath", element: <LegalDocument/>},
        {path: "page/:pagePath", element: <DynamicPages/>},
        {path: "recommendations", element: <Recommendations/>},
        {path: "*", element: <Navigate to="/" replace/>}
    ]

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const showOrders = queryParams.get('showOrders');
        const showOrder = queryParams.get('showOrder');
        const i = queryParams.get('i'); // initiator - one of o (open orders) or l (order list)

        if (showOrders || initiator) {
            setOrderId(null);
            dispatch(showModal(showOrders === "open" || initiator == 'openOrders' ? Modals.OPEN_ORDER_LIST : Modals.ORDER_LIST));
            setInitiator(null);
        } else if (showOrder) {
            setOrderId(showOrder);
            setInitiator(i == 'o' ? 'openOrders' : 'orderList')
            dispatch(showModal(Modals.ORDER_DETAILS));
        } else {
            dispatch(hideAllModals());
        }
    }, [location.search, dispatch]);

    useEffect(() => {
        const modals = Object.values(modalStates)
        if (modals.length > 0 && modals.every(modalState => modalState === false)) {
            const currentPath = location.pathname;
            navigate(`${currentPath}`, {replace: true});
        }
    }, [modalStates, navigate]);

    useEffect(() => {
        if (isOrderDetailsModalVisible === false) {
            setOrderId(null)
        }
    }, [isOrderDetailsModalVisible]);


    const closeOrderDetailsModal = () => {
        console.log("closeOrderDetailsModal")
        // no logic needed, the search params closing the dialog
    };

    return (
        <>
            <NavBar/>
            <main style={{minHeight: "calc(100vh - 92px)"}} role="main" id="main">
                <Routes>
                    {ROUTER_PAGES.map((route, index) =>
                        <Route key={index} {...route}/>
                    )}
                </Routes>
                {/* Conditionally render the OrderListModal */}
                {isOrderListModalVisible && (
                    <OrderListModal open={isOrderListModalVisible} openOrdersOnly={false}
                                    handleClose={() => dispatch(hideModal(Modals.ORDER_LIST))}/>
                )}
                {isOpenOrdersListModalVisible && (
                    <OrderListModal open={isOpenOrdersListModalVisible} openOrdersOnly={true}
                                    handleClose={() => dispatch(hideModal(Modals.OPEN_ORDER_LIST))}/>
                )}
                {isOrderDetailsModalVisible && (
                    isMobile ?
                        <OrderDetailsMobileModal orderId={orderId}
                                                 open={isOrderDetailsModalVisible}
                                                 handleClose={closeOrderDetailsModal}/>
                        :
                        <OrderDetailsDesktopModal orderId={orderId}
                                                  open={isOrderDetailsModalVisible}
                                                  handleClose={closeOrderDetailsModal}/>
                )}
                {isNavigatingToPaymentModalVisible && (
                    <Modal show={isNavigatingToPaymentModalVisible} centered size="sm">
                        <Modal.Body className="text-center fs-5 p-4">
                            <div>מעבירים אותך לעמוד התשלום</div>
                            <div>זה יקח עוד רגע</div>

                            <div className="text-center">
                                <Spinner size={53}/>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
                {isHelperVideoModalVisible && (
                    <HelperVideoModal show={isHelperVideoModalVisible}
                                      handleClose={() => dispatch(hideModal(Modals.HELPER_VIDEO))}/>
                )}
            </main>
            <Footer/>
        </>
    );
};

export default AppLayout;
